@import "@design";

.pagination-date-picker-input {
  .v-text-field__slot input {
    font-size: 2rem;
    line-height: 1.3;
  }
  // icon
  .v-input__prepend-outer {
    margin-top: 12px;
  }
}
